<template>
    <div class="spc-dashboard"></div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>